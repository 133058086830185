import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'

import VideosFilter from '../components/VideosFilter/VideosFilter'

import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function FilmAndProductionPage({ data: { strapi: { filmAndVideoPage, videoTags } }, location }) {

	return (
		<>
			<Seo title="Film &amp; Video Production" description={ filmAndVideoPage.seoDescription } pathname={ location.pathname } />

			{ filmAndVideoPage && filmAndVideoPage.content &&
				<DynamicZone components={ filmAndVideoPage.content } />
			}

			<VideosFilter filters={ videoTags } defaultTag={ filmAndVideoPage.video_tag } />
		</>
	)
}

export const query = graphql`
	query filmAndVideoPageQuery {
		strapi {
			filmAndVideoPage {
				seoDescription
				video_tag {
					id
					tag
				}
				content {
					__typename
					... on StrapiQuery_ComponentServicesServiceHero {
						id
						serviceDescription
						serviceTags
						serviceTitle
						serviceImage {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
					... on StrapiQuery_ComponentServicesVideoHero {
						id
						videoHeroNumber
						videoHeroTitle
						videoHeroImage {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
					... on StrapiQuery_ComponentServicesFeaturedVideo {
						id
						videoID
						videoHeading
						videoDescription
					}
				}
			}
			videoTags {
				id
				tag
			}
		}
	}
`