import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { BsFillPlayFill } from 'react-icons/bs'

import FullScreen from '../FullScreen/FullScreen';

import { toggleBodyLock } from '../../helpers/bodyLock'

const Video = ( props ) => {

	const { video, index } = props
	const [ play, setPlay ] = useState( false )
	const [ loading, setLoading ] = useState( false )

	const handleClick = () => {
		toggleBodyLock( true )
		setPlay( true )
		setLoading( true )
	}

	return (
		<>
			{ play &&
				<FullScreen setPlay={ setPlay } loading={ loading }>
					<iframe
						className="video-grid__video"
						src={`https://player.vimeo.com/video/${ video.videoNumber }`}
						title={`${ video.company } Montage Video`}
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						frameBorder="0"
						onLoad={ () => setLoading( false ) }
						webkitallowfullscreen="true"
						mozallowfullscreen="true"
						allowFullScreen
					/>
				</FullScreen>
			}
			<button key={ index } className="video-grid__grid-item" onClick={ () => handleClick() }>
				<GatsbyImage image={ video.image.imageFile.childImageSharp.gatsbyImageData } className="video-grid__image" alt="" />

				<div className="video-grid__info-container">
					{ video.company && <div className="video-grid__company">{ video.company }</div> }
					{ video.description && <div className="video-grid__description">{ video.description }</div> }
				</div>
				<BsFillPlayFill className="video-grid__play" />
			</button>
		</>
	)
}

export default Video