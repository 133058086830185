import React from 'react';

import Video from './Video'

const VideoGrid = ( props ) => {

	return (
		<div className="video-grid">
			{ props.content.map( ( video, index ) => (
				<Video video={ video } key={ index } index={ index } />
			))}
		</div>
	)
}

export default VideoGrid