import React, { useEffect, useState } from 'react'

import { gql } from '@apollo/client';

import VideoGrid from '../VideoGrid/VideoGrid';
import ApolloFilter from '../ApolloFilter/ApolloFilter';

export default function VideosFilter ( props ) {

	const { filters, defaultTag } = props

	const [ videoTag, setVideoTag ] = useState(defaultTag?.id ? defaultTag.id : [])
	const [ filterState, setFilterState ] = useState({
		video_tags: {
			id: videoTag
		},
	})

	const productQuery = gql`
		query getPhotos ($start: Int, $limit: Int, $sort: String, $where: JSON) {
			videos ( start: $start, limit: $limit, sort: $sort, where: $where) {
				company
				description
				videoNumber
				image {
					url
					formats
					width
					height
				}
			}
		}
	`;

	useEffect(() => {
		setFilterState( {
			video_tags : {
				id: videoTag
			}
		})
	}, [ videoTag ])
	
	return (
		<ApolloFilter productQuery={ productQuery } tag={ videoTag } setTag={ setVideoTag } filterState={ filterState } filters={ filters } isVideo={ true }>
			<VideoGrid />
		</ApolloFilter>
	)
}